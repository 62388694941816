<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card color='#174282'>
          <template v-slot:heading>
            <div
              class="display-2"
            >
              Vos inscriptions
            </div>
          </template>
          <v-card-actions>
            <v-row>
              <v-col
                offset-md=3
                md=3
                offset=4
                cols=8
              >
                <v-btn href="#conferences"
                class="primary">
                  Voir les ateliers
                </v-btn>
              </v-col>
              <v-col
                md=3
                offset-md=0
                offset=4
                cols=8
              >
                <v-btn href="#consultations"
                class="primary" style="color:#81cf10">
                  Voir les consultations
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-row id="conferences">
            <v-col
              cols="11"
              offset="1"
            >
              <h2> * Ateliers</h2>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                class="mx-auto my-12"
                max-width="500"
              >
                <v-card-title class="justify-center">Jeudi 30 septembre</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <div v-if="!conferences.jeudi.filter(function(item){ return item.subCount.includes(userId.toString())}).length">
                  <v-card-text>
                    Aucune inscription pour cette date.
                  </v-card-text>
                </div>
                <div
                  v-for="conference in conferences.jeudi.filter(function(item){ return item.subCount.includes(userId.toString())})"
                  :key="conference.id"
                >
                  <v-card-text>
                    <h4>{{ conference.name }}</h4>
                    <p v-html="conference.intervenants"></p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="red"
                      @click="unSubcribeToConf (conference.id)"
                      v-if="userId && conference.subCount.includes(userId.toString())"
                    >
                      Se desinscrire de cette atelier
                    </v-btn>
                  </v-card-actions>
                  <v-divider class="mx-4"></v-divider>
                </div>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                class="mx-auto my-12"
                max-width="500"
              >
                <v-card-title class="justify-center">Vendredi 1 octobre</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <div v-if="!conferences.vendredi.filter(function(item){ return item.subCount.includes(userId.toString())}).length">
                  <v-card-text>
                    Aucune inscription pour cette date.
                  </v-card-text>
                </div>
                <div
                  v-for="conference in conferences.vendredi.filter(function(item){ return item.subCount.includes(userId.toString())})"
                  :key="conference.id"
                >
                  <v-card-text>
                    <h4>{{ conference.name }}</h4>
                    <p v-html="conference.intervenants"></p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="red"
                      @click="unSubcribeToConf (conference.id)"
                      v-if="userId && conference.subCount.includes(userId.toString())"
                    >
                      Se desinscrire de cet atelier
                    </v-btn>
                  </v-card-actions>
                  <v-divider class="mx-4"></v-divider>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row id="consultations">
            <v-col
              cols="11"
              offset="1"
            >
              <h2 style="color:#81cf10"> * Consultations</h2>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                class="mx-auto my-12"
                max-width="500"
              >
                <v-card-title>jeudi 30 septembre</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <div v-if="!consultations.filter(function(item){ return item.Date === '2021-09-30' && item.USR_ID === userId.toString()}).length">
                  <v-card-text>
                    Aucune inscription pour cette date.
                  </v-card-text>
                </div>
                <div
                  v-for="consultation in consultations.filter(function(item){ return item.Date === '2021-09-30' && item.USR_ID === userId.toString() })"
                  :key="consultation.id"
                >
                  <v-card-text>
                    <h3>{{ consultation.TimeStart.split(':')[0] }}H{{ consultation.TimeStart.split(':')[1] }}-{{ consultation.TimeEnd.split(':')[0] }}H{{ consultation.TimeEnd.split(':')[1] }} : 
                      {{ consultation.FFD_Name }}
                    </h3>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="#174282"
                      @click="subcribeToConsultation (consultation.FFC_ID)"
                      v-if="userId && consultation.USR_ID != userId"
                    >
                      Réserver cette consultation
                    </v-btn>
                    <v-btn
                      text
                      color="red"
                      @click="unSubcribeToConsultation (consultation.FFC_ID)"
                      v-else-if="userId && consultation.USR_ID == userId"
                    >
                      Annuler cette consultation
                    </v-btn>
                    <v-btn
                      text
                      color="#174282"
                      v-else
                      @click="openModal"
                    >
                      Connexion requise pour réserver
                    </v-btn>
                  </v-card-actions>
                  <v-divider class="mx-4"></v-divider>
                </div>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                class="mx-auto my-12"
                max-width="500"
                v-if="consultations"
              >
                <v-card-title>vendredi 1 octobre</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <div v-if="!consultations.filter(function(item){ return item.Date === '2021-10-01' && item.USR_ID === userId.toString()}).length">
                  <v-card-text>
                    Aucune inscription pour cette date.
                  </v-card-text>
                </div>
                <div
                  v-for="consultation in consultations.filter(function(item){ return item.Date === '2021-10-01' && item.USR_ID === userId.toString() })"
                  :key="consultation.id"
                >
                  <v-card-text>
                    <h3>{{ consultation.TimeStart.split(':')[0] }}H{{ consultation.TimeStart.split(':')[1] }}-{{ consultation.TimeEnd.split(':')[0] }}H{{ consultation.TimeEnd.split(':')[1] }} : 
                      {{ consultation.FFD_Name }}
                    </h3>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="#174282"
                      @click="subcribeToConsultation (consultation.FFC_ID)"
                      v-if="userId && consultation.USR_ID != userId"
                    >
                      Réserver cette consultation
                    </v-btn>
                    <v-btn
                      text
                      color="red"
                      @click="unSubcribeToConsultation (consultation.FFC_ID)"
                      v-else-if="userId && consultation.USR_ID == userId"
                    >
                      Annuler cette consultation
                    </v-btn>
                    <v-btn
                      text
                      color="#174282"
                      @click="openModal"
                      v-else
                    >
                      Connexion requise pour réserver
                    </v-btn>
                  </v-card-actions>
                  <v-divider class="mx-4"></v-divider>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <hr/>
    <v-row>
      <v-col cols="4" md="4" style="text-align: center">
          Organisé par <a href="https://hors-pair.com/">Hors-Pair</a>
      </v-col>
      <v-col cols="4" offset-md="4" md="4" style="text-align: center">
          Powered by <a href="https://www.kertios.com">Kertios</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import { httpGet, httpPost } from '@/plugins/axios'
  export default {
    name: 'Profile',
    computed: {
      ...mapState(['isAdmin']),
    },
    data () {
      return {
        snackbar: false,
        text: '',
        render: 0,
        timeout: 2000,
        modal: false,
        userId: '',
        consultations: null,
        element: 'conferences',
        conferences: {
          jeudi: [
            {
              id: 1,
              dataEmail: 'de 08h30-09h45 le 30/09/2021',
              name: '08h30-09h45 Choisir mes associés et ma forme juridique : 2 piliers clés de mon entreprise',
              description: 'Votre avocat vous conseille dans le choix de la structure et de vos partenaires en fonction de votre activité et de vos objectifs.',
              maximum: 80,
              subCount: [],
              intervenants: '<strong>Sandrine CLEVENOT</strong>, avocat au barreau des Hauts-de-Seine, CorpoLegem.<br/>'+
                '<strong>Nadine JOUANNIC</strong>, chargée de développement économique à la CMA Hauts-de-Seine.<br/>'+
                '<strong>Stéphanie HUET</strong>, conseillère création et développement de la jeune entreprise à la CCI Hauts-de-Seine.<br/><br/>'+
                'Avec le témoignage des dirigeantes d’AVHESTIA <strong>Virginie SEBBAN GIRAUDEL</strong> Présidente et Ariane COURTOIS-DEMANGE, Directrice Générale.'

            },
            {
              id: 2,
              dataEmail: 'de 10h00-11h15 le 30/09/2021',
              name: '10h00-11h15 Handicap et droit pour enrichir l\'entreprise',
              description: 'Au-delà du nouveau dispositif du 1er janvier pour promouvoir l\'emploi des travailleurs en situation de handicap ,l\'apport du handicap dans l\'entreprise.',
              maximum: 80,
              subCount: [],
              intervenants: '<strong>Stéphane BALLER</strong>, avocat au barreau de Paris, De Gaulle Fleurance & associés, fondateur de Droit comme un H !.<br/>'+
                '<strong>Alexia RIGAUD</strong>, avocat au barreau des Hauts-de-Seine, PWC Société d’avocats, membre du Conseil d’administration de Droit comme un H !.<br/><br/>'+ 
                'Avec le témoignage de <strong>Marie HOMBROUX</strong>, associé fondateur de Atorus Exécutive, cabinet de recrutement en RH et juridique, <strong>Justine MARTIN-PINAUD</strong>, fondatrice de Ereden RH et <strong>Katia DAYAN</strong>, présidente et fondatrice Les Papillons de jours.',
            },
            {
              id: 3,
              dataEmail: 'de 11h30-12h45 le 30/09/2021',
              name: '11h30-12h45 Quelle stratégie de financement pour accélérer mon développement ?',
              description: 'L\'avocat vous conseille sur les différentes solutions de financement au regard de la situation particulière et des projets de votre entreprise.',
              maximum: 80,
              subCount: [],
              intervenants: '<strong>Vincent MAUREL</strong>, avocat au barreau des Hauts-de-Seine, membre du Conseil de l’Ordre, ancien bâtonnier, membre du CNB, KPMG avocats.<br/>'+ 
                '<strong>Alexandre BORDENAVE</strong>, avocat au barreau des Hauts-de-Seine, membre du Conseil de l’Ordre, CMS Francis Lefebvre avocats. <br/>'+
                '<strong>Assia CHOUAI</strong>, chargée d’affaire à la CCI Paris Ile-de-France.<br/>'+ 
                '<strong>Alix BAUDOUX</strong>, directrice du centre d’affaires des Hauts-de-Seine, BRED.',
            },
            {
              id: 4,
              dataEmail: 'de 13h00-14h15 le 30/09/2021',
              name: '13h00-14h15 Cybersécurité',
              description: 'La cyber sécurité est un enjeu fondamental dans les entreprises. Retrouvez le témoignage d’un professionnel de la sécurité.',
              maximum: 80,
              subCount: [],
              intervenants: '<strong>Fabrice NAFTALSKI</strong>, avocat au barreau des Hauts-de-Seine, EY Société d’avocats.</br>'+ 
                '<strong>Adrien SALART</strong>, Chargé de développement économique à la CMA Ile-de-France -Hauts-de-Seine.</br><br/>'+
                'Avec le témoignage de <strong>Benoit PAYAN</strong>, conférencier au ministère de l’intérieur',
            },
            {
              id: 5,
              dataEmail: 'de 14h30-15h45 le 30/09/2021',
              name: '14h30-15h45 La fiducie, ou reine des sûretés',
              description: 'L\'avocat a le monopole aux côtés des établissements de crédit de la fiducie outil indispensable à l\'emprunteur pour obtenir plus et à des meilleures conditions',
              maximum: 80,
              subCount: [],
              intervenants: '<strong>Theophile FORNACCIARI</strong>, administrateur judiciaire-fiduciaire,<br/><br/>'+
                'Avec le témoignage de <strong>Edouard VAUJOUR</strong>, co-fondateur chez KNAVE et <strong>Céline GUILLEMOT</strong>, responsable pôle retournement-services et financement auprès des Entreprises en retournement, BRED.',
            },
            {
              id: 6,
              dataEmail: 'de 16h00-17h15 le 30/09/2021',
              name: '16h00-17h15 La reprise d\'entreprises à la barre ',
              description: 'Les avantages, les inconvénients et les risques expliqués par votre avocat.',
              maximum: 80,
              subCount: [],
              intervenants: '<strong>Arnaud PEDRON</strong>, avocat au barreau des Hauts-de-Seine, membre du Conseil de l’Ordre Franklin Société d’avocats.<br/>'+ 
                '<strong>Romain GRAU</strong>, député des Pyrénées-Orientales – membre de la commission des finances de l’Assemblée Nationale - président de la mission d’information commune relative aux entreprises du fait de la crise sanitaire.<br/>'+ 
                '<strong>Jacques FINESCHI</strong>, président du tribunal de commerce de Nanterre.<br/><br/>'+
                'Avec le témoignage de : <strong>Mathieu BOULLENGER</strong>, directeur chez ESS’TEAM CONSEIL – Directeur de l’Economie Sociale et Solidaire chez PAPREC GROUP.',
            },
          ],
          vendredi: [
            {
              id: 7,
              dataEmail: 'de 08h30-09h45 le 01/10/2021',
              name: '08h30-09h45 Penser le conflit autrement',
              description: 'Présentation des différents modes de règlements amiables des différends.',
              maximum: 80,
              subCount: [],
              intervenants: '<strong>Sonia KOUTCHOUK</strong>, avocat au barreau des Hauts-de-Seine, médiateur, 44 Bis avocats.<br/>'+ 
                '<strong>Mariannick CANEVET</strong>, avocat au barreau des Hauts-de-Seine.<br/>'+ 
                '<strong>Claude DUVERNOY</strong>, avocat au barreau des Hauts-de-Seine, ancien bâtonnier, président de Médiation en Seine, Droit Fil Avocats.',
            },
            {
              id: 8,
              dataEmail: 'de 10h00-11h15 le 01/10/2021',
              name: '10h00-11h15 Prévention et nouvelle procédure de traitement de sortie de crise',
              description: 'Quand et comment les utiliser pour prévenir les risques et sortir de la crise ?',
              maximum: 80,
              subCount: [],
              intervenants: '<strong>Ludivine JOUHANNY</strong>, avocat au barreau des Hauts-de-Seine, Vice-présidente du CIP92, expert de 60000 rebonds, sentinelle de l’APESA, cabinet JL Avocat.<br/>'+ 
                '<strong>Lionnel RAINFRAY</strong>, président du MEDEF 92.<br/>'+ 
                '<strong>Emmanuel CORDIER</strong>, vice-président de la Compagnie régionale des commissaires aux comptes de Versailles et du Centre.<br/>'+ 
                '<strong>Alix BAUDOUX</strong>, Directrice du centre d’Affaires des Hauts-de-Seine.<br/>'+ 
                '<strong>Nadine JOUANNIC</strong>, chargée de Développement Economique à la CMA Ile-de-France -Hauts-de-Seine.<br/>'+ 
                '<strong>Christopher MARCELIN</strong>, conseiller Entreprises en difficulté auprès de la CCI Paris – Ile-de-France.',
            },
            {
              id: 9,
              dataEmail: 'de 11h30-12h45 le 01/10/2021',
              name: '11h30-12h45 Mon bail commercial',
              description: 'Le bail commercial est un contrat essentiel que votre avocat sécurisera.',
              maximum: 80,
              subCount: [],
              intervenants: '<strong>Jean-Philippe MARIANI</strong>, avocat au barreau des Hauts-de-Seine, cabinet M.<br/>'+ 
              '<strong>Nadine JOUANNIC</strong>, chargée de développement économique à la CMA Hauts-de-Seine',
            },
            {
              id: 10,
              dataEmail: 'de 13h00-14h15 le 01/10/2021',
              name: '13h00-14h15 La gestion de crise ',
              description: 'L\'avocat vous accompagne pour mettre en place un plan de crise.',
              maximum: 80,
              subCount: [],
              intervenants: '<strong>Maxime CESSIEUX</strong>, avocat au barreau des Hauts-de-Seine, cabinet 54.<br/>'+ 
                '<strong>Catherine CASSAN</strong>, avocat au barreau des Hauts-de-Seine, PWC Société d’avocats.',
            },
            {
              id: 11,
              dataEmail: 'de 14h30-15h45 le 01/10/2021',
              name: '14h30-15h45 Patrimoine du dirigeant ',
              description: 'Panel des différentes protections à mettre en œuvre pour le chef d\'entreprise.',
              maximum: 80,
              subCount: [],
              intervenants: '<strong>Sylvie VAQUIERI</strong>, avocat au barreau des Hauts-de-Seine, membre du Conseil de l’Ordre, FIDAL Société d’avocats.<br/>'+ 
                '<strong>Marie Lorraine HENRY</strong>, avocat au barreau des Hauts-de-Seine, FIDAL Société d’avocats.<br/>'+ 
                '<strong>Denis PREVOST</strong> directeur Cercle Patrimonial Hauts de Seine, BRED <br/><br/>'+
                'Avec le témoignage de la mutuelle des indépendants GARANCE',
            },
            {
              id: 12,
              dataEmail: 'de 16h00-17h15 le 01/10/2021',
              name: '16h00-17h15 Télétravail',
              description: 'Avantages du télétravail riment avec risques à sécuriser avec votre avocat.',
              maximum: 80,
              subCount: [],
              intervenants: '<strong>Camille GAMET</strong>, avocat au barreau des Hauts-de-Seine, EY Société d’avocats.<br/>'+
                '<strong>Sylvie MAGNEN</strong> avocat au barreau des Hauts-de-Seine, membre du Conseil de l’Ordre, EY Société d’Avocats.<br/>'+
                '<strong>Benoit RABOURDIN</strong>, CEO de Hutobee',
            },
          ],
        },
      }
    },
    mounted () {
      this.getData()
    },
    methods: {
      openModal () {
        this.modal = true
      },
      async getData () {
        const consultations = await httpGet('getConsultations')
        console.log(consultations)
        this.consultations = consultations.Consultations
        this.userId = this.$store.state.userId
        this.conferences.jeudi.forEach(
          async (conference) => {
            const users = await httpPost('getSubByConfId', {
              confId: conference.id,
            })
            conference.subCount = []
            users.Count.forEach(user => {
              conference.subCount.push(user.USR_ID)
            })
          },
        )
        this.conferences.vendredi.forEach(
          async (conference) => {
            const users = await httpPost('getSubByConfId', {
              confId: conference.id,
            })
            conference.subCount = []
            users.Count.forEach(user => {
              conference.subCount.push(user.USR_ID)
            })
          },
        )
      },
      displaySnack (message) {
        this.text = message
        this.snackbar = true
      },
      async subcribeToConf (id, max) {
        const response = await httpPost('subToConf', {
          userId: this.$store.state.userId,
          confId: id,
          max: max,
        })
        console.log(response)
        let message = 'Vous êtes bien enregistré pour cet atelier.'
        if (response.Error) {
          message = response.Error
        }
        this.displaySnack(message)
        this.getData()
      },
      async unSubcribeToConf (id) {
        const response = await httpPost('unSubToConf', {
          userId: this.$store.state.userId,
          confId: id,
        }).then(this.getData())
        var message = 'Votre réservation pour cet atelier a bien été annulée.'
        if (response.Error) {
          message = response.Error
        }
        this.displaySnack(message)
        this.getData()
      },
      async subcribeToConsultation (id) {
        await httpPost('subToConsultation', {
          userId: this.$store.state.userId,
          consultationId: id,
        })
        this.getData()
        this.displaySnack('Vous avez reservé cette consultation avec succés.')
      },
      async getSubByConf (id) {
        await httpPost('getSubByConfId', {
          confId: id,
        })
      },
      async unSubcribeToConsultation (id) {
        await httpPost('unSubToConsultation', {
          consultationId: id,
        })
        this.getData()
        this.displaySnack('Votre réservation pour cette consultation a bien été annulée.')
      },
    },
  }
</script>
